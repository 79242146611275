

import Styles from "../styles/grid";
import MileboardSVG from "../../../../assets/MileboardSVG.svg";
import {
  TbScreenShare
} from "react-icons/tb";
import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BaseContext } from "../../../../app/BaseConext";
import { roomSlice } from "../../store/slice";

function SidebarShare(props) {
  const dispatch = useDispatch();
  const [showMileBoard, setShowMileBoard] = useState(false);
  const {
    HeaderColText,
    SidebarShareRow,
    SidebarHeaderRow,
    Sidebar,
    SidebarHeading,
    SidebarHeader,
    SidebarHeaderButton,
    SidebarHR,
    SidebarShareIcon,
  } = Styles;
  const { onClickSidebarClose } = props;
  const { MyStreams, SocketConnection } = useContext(BaseContext);
  const isScreenShare = useSelector(
    ({ roomSlice: { uiState } }) => uiState.isScreenShare
  );
  const users = useSelector(({ roomSlice: { users } }) => users);
  const screenShareAllow = useSelector(
    ({ roomSlice: { uiState } }) => uiState.screenShareAllow
  );
  // const users=useSelector(({roomSlice:{users}})=>users);
  const handleScreenShare = async () => {
    if (!screenShareAllow) return alert("Someone already shared screen");
    console.log("------------", isScreenShare);
    if (isScreenShare) {
      MyStreams.deleteScreenShare().then(() => {
        SocketConnection.screenShareOff();
      });
    } else {
      MyStreams.getScreenShare().then(async ({ screenShare }) => {
        screenShare.getVideoTracks()[0].addEventListener("ended", () => {
          MyStreams.deleteScreenShare().then(() => {
            SocketConnection.screenShareOff();
          });
        })
        SocketConnection.screenShareOn({ screenShare, users });
      });
    }
  };
  const isMileBoardShare = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.isMileBoardShare;
  });

  const handleMileBoardShare = () => {
    console.log("handleMileBoardShare", isMileBoardShare)
    dispatch(roomSlice.actions.updateMileBoardShare(!isMileBoardShare));
  }
  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarHeading> Share </SidebarHeading>
        <SidebarHeaderButton onClick={() => { onClickSidebarClose() }}>X</SidebarHeaderButton>
      </SidebarHeader>
      <SidebarHR style={{ marginTop: "0px", marginBottom: "10px" }} />
      <SidebarHeaderRow justify="center">

        <SidebarShareRow onClick={handleScreenShare}>
          <SidebarShareIcon>
            <TbScreenShare size={20} style={{ color: "#1A5669" }} />
          </SidebarShareIcon>
          <HeaderColText>
            Screen Share
          </HeaderColText>
        </SidebarShareRow>

        {/* <SidebarShareRow onClick={handleMileBoardShare} >
          <a style={{display:"flex",flexDirection:"row" , columnGap:"15px" , alignItems:"center"}} href="http://board.workw.com/mileboard/ggwp" target="_blank">
            <SidebarShareIcon>
              <img src={MileboardSVG} />
            </SidebarShareIcon>
            <HeaderColText>
              MileBoard
            </HeaderColText>
          </a>
        </SidebarShareRow> */}


      </SidebarHeaderRow>
    </Sidebar>
  );
}


export default SidebarShare;