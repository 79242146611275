import { createContext } from "react";
import Socket from "../data/socket/Socket";
import MyStreams from "../data/stream/MyStream";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const SOCKET_STATE = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  RECONNECTING: "reconnecting",
};

export const BaseContext = createContext(null);
export let AllPeerConnection;
const BaseContextProvider = ({ children }) => {
  const disptach = useDispatch();
  const naviagte = useNavigate();
  const SocketConnection = new Socket(disptach,naviagte); //socket is user and peerconnection is room


  return (
    <BaseContext.Provider
      value={{
        MyStreams,
        SocketConnection,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};
export default BaseContextProvider;
