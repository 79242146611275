import Styles from "../styles/grid";
import React, { useState, useEffect } from "react";
import VideoStreamTagComponent from "./VideoStreamTagComponent";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import ScreenShareComponent from "./ScreenShareComponent";
import { BiCopyright } from "react-icons/bi";
function SidebarLayout(props) {
  const {
    Sidebar,
    SidebarHeaderRow,
    SidebarHeading,
    SidebarHeader,
    SidebarHeaderButton,
    SidebarHR,
    SidebarHeaderCol,
    SidebarBody,
    SidebarBodyCol,
  } = Styles;

  const users = useSelector(({ roomSlice: { users } }) => users);
  const userIds = useSelector((state) => state.roomSlice.userIds);
  const myId = useSelector((state) => state.generalSlice.userId);
  const pinCount = useSelector((state) => state.roomSlice.uiState.pinCount);
  const viewType = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.viewType;
  });

  const [displayUsers, setDisplayUsers] = useState([]);
  // const displayUsers = useRef([]);
  const dispatch = useDispatch();
  // console.log("PINCOUNT:", pinCount);
  useEffect(() => {
    let mainScreenGridCount =
      viewType === 1
        ? 8
        : viewType === 2
          ? 6
          : viewType === 3
            ? 4
            : viewType === 4
              ? 4
              : 0;
    let SidebarScreenUsers = [];
    if (pinCount === 0) {
      if (userIds.length > mainScreenGridCount) {
        let displayableUsers = userIds.slice(
          mainScreenGridCount,
          userIds.length
        );
        setDisplayUsers(displayableUsers);
      } else {
        setDisplayUsers([]);
      }
    }
    if (pinCount !== 0) {
      if (pinCount > mainScreenGridCount) {
        let pinnedUsers = [];
        SidebarScreenUsers = [];
        // let allUsers=[...Object.keys(otherUsers),myId];
        for (let user in users) {
          if (users[user].uiState.pin) pinnedUsers.push(user);
        }
        const updatedMainScreenUsers = pinnedUsers.slice(
          0,
          mainScreenGridCount
        );
        // console.log("PINNED USERS",updatedMainScreenUsers);
        // const remainingMainScreenUsers=pinnedUsers.slice(mainScreenGridCount,pinnedUsers.length);
        console.log("updatedMainScreenUsers", updatedMainScreenUsers);
        // console.log("remainingMainScreenUsers",remainingMainScreenUsers);
        const updatedLayoutUsers = userIds.filter(user => !updatedMainScreenUsers.includes(user));
        console.log("updatedLayoutUsers", updatedLayoutUsers);
        // let displayableUsers=allUsers.slice(mainScreenGridCount,allUsers.length);
        dispatch(
          roomSlice.actions.updateMainScreenUsers({
            updatedMainScreenUsers,
            myId,
          })
        );
        dispatch(
          roomSlice.actions.updateSidebarUsers({ updatedLayoutUsers, myId })
        );
        dispatch(
          roomSlice.actions.updatePinCountForLayout({ updatedMainScreenUsers })
        );
        setDisplayUsers(updatedLayoutUsers);

        // setDisplayUsers(updatedMainScreenUsers);
      } else {
        SidebarScreenUsers = [];
        for (let user in users) {
          if (!users[user].uiState.pin) SidebarScreenUsers.push(user);
        }
        // console.log(SidebarScreenUsers, "sidebarScreenUsers");
        setDisplayUsers(SidebarScreenUsers);
        // displayUsers.current=SidebarScreenUsers;
      }
    }
    // console.log("SideBarLayout Component USEEFFECT");
  }, [viewType, pinCount, userIds]);
  // console.log("updatedLayoutUsers",displayUsers);
  const { onClickSidebarClose } = props;
  // console.log("SideBarLayout Component");style={{position: "relative"}} at videobox
  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarHeading> Meeting Participants </SidebarHeading>
        <SidebarHeaderButton
          onClick={() => {
            onClickSidebarClose();
          }}
        >
          X
        </SidebarHeaderButton>
      </SidebarHeader>
      <SidebarHR style={{ marginTop: "0px" }} />
      {/* <SidebarHeaderRow justify="center">
        <SidebarHeaderCol span={7} sidebarlayout="true" />
        <SidebarHeaderCol span={7} sidebarlayout="true" />
        <SidebarHeaderCol span={7} sidebarlayout="true" />
      </SidebarHeaderRow>
      <SidebarHR /> */}
      <SidebarBody style={{ marginBottom: "15px" }}>
        {displayUsers.map((userId, index) => {
          const screenShare = userId.endsWith("_screen_share");
          const myId = screenShare
            ? userId.slice(0, userId.length + 13)
            : userId;
          if (screenShare)
            return (
              <div className="sideBarLayout" key={userId}>
                <SidebarBodyCol className="videoBox">
                  <ScreenShareComponent
                    userId={userId}
                    style={{ border: "none", position: "relative" }}
                    sidebarlayout={"sidebarlayout"}
                  />
                </SidebarBodyCol>
              </div>
            );
          return (
            <div className="sideBarLayout" key={userId}>
              <SidebarBodyCol className="videoBox">
                <VideoStreamTagComponent
                  userId={userId}
                  style={{ border: "none", position: "relative", height: "140px" }}
                  myId={myId}
                  sidebarlayout={"sidebarlayout"}
                />
              </SidebarBodyCol>
            </div>
          );
        })}
      </SidebarBody>
    </Sidebar>
  );
}

export default SidebarLayout;
