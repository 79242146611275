
export const copyURL = (roomId, messageApi) => {
    const URL = window.location.origin + "/" + roomId;
    navigator.permissions
        .query({
            name: "clipboard-write",
        })
        .then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText(URL).then(
                    () => {
                        messageApi.open({
                            type: 'success',
                            content: 'Copied meeting link',
                            duration: 3,
                        });
                    },
                    () => {
                        messageApi.open({
                            type: 'error',
                            content: 'Copying meeting link failed',
                            duration: 3,
                        });
                    }
                );
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'CWrite access not granted!',
                    duration: 3,
                });
            }
        });
};