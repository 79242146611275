import styled, { css } from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Alert } from "antd";
import {
  IoMicOutline,
  IoMicOffOutline,
  IoVideocamOutline,
  IoVideocamOffOutline,
  IoCameraReverseOutline
} from "react-icons/io5";

const PrimaryColor = "#1a5669";
const SecondaryColor = "#ffff";
// const TertiaryColor = "#757d86";
// const BlackColor = "#000";
const GrayColor = "#D3D3D3";


const ToggleCameraIcon = styled(IoCameraReverseOutline)`
  @media (min-width: 915px) {
    font-size: 22px;
  }
`;
const VideoOnIcon = styled(IoVideocamOutline)`
  @media (min-width: 915px) {
    font-size: 22px;
  }
`;
const VideoOffIcon = styled(IoVideocamOffOutline)`
  @media (min-width: 915px) {
    font-size: 22px;
  }
`;
const MicOffIcon = styled(IoMicOffOutline)`
  @media (min-width: 915px) {
    font-size: 22px;
  }
`;
const MicOnIcon = styled(IoMicOutline)`
  @media (min-width: 915px) {
    font-size: 22px;
  }
`;
const FloatingButton = styled.button`
  ${(props) =>
    css`
      background: ${PrimaryColor};
      color: ${SecondaryColor};
      border-radius: 50px;
      border: 2px solid ${PrimaryColor};
      display: flex;
      gap: 5px;

      font-weight: 500;
      align-items: center;
      justify-content: center;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke, opacity, box-shadow, transform,
        filter, backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      padding: 8px;
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 915px) {
        font-size: 16px;
      }
    `}
`;
const ChipButton = styled.button`
  ${(props) =>
    css`
      background: ${props.active ? PrimaryColor : SecondaryColor};
      color: ${props.active ? SecondaryColor : PrimaryColor};
      border-radius: ${props.borderRadius};
      border: 2px solid ${props.bordercolor};
      display: flex;
      gap: 5px;
      width: ${props.width};
      font-weight: 500;
      align-items: center;
      justify-content: center;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke, opacity, box-shadow, transform,
        filter, backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      padding: ${props.paddingVertical} 10px ${props.paddingVertical} 10px;
      font-size: 18px;
      cursor: ${props.active ? "default" : "pointer"};
      &:hover {
        background: ${props.active ? "" : PrimaryColor};
        color: ${props.active ? "" : SecondaryColor};
        border: 2px solid ${props.hoverColor};
      }
      ${props.disabled
        ? `
          background: ${GrayColor};
          color: white;
          border: 2px solid ${GrayColor};
          &:hover {
            background: ${GrayColor};
            color: white;
            border: 2px solid ${GrayColor};
          }
        `
        : ``}
      @media (max-width: 915px) {
        font-size: 16px;
      }
    `}
`;

const Spinner = styled(Spin)`
  background-color: black; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 105.5%;
`;
const LoadingSpinIcon = styled(LoadingOutlined)`
  font-size: 5rem;
`;
const DeviceAlert = styled(Alert)`
  z-index: 1;

  border-radius: 5px;
  ${(props) => css`
    width: ${props.width};
    ${props.absolute ? "position: absolute;top: 20px;" : ""}
  `}
`;
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80%;
  @media (max-width: 400px) {
    height: 100%;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 915px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const VideoDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding-top: 2%;
  padding-left: 5%;
  @media (max-width: 915px) {
    width: 70%;
    padding-top: 2%;
    padding-left: 0% ;
    padding-right: 2% ;
  }
`;
const CallOptionButton = styled.div`
  display: flex;
  gap: 15px;
  position: relative;
  bottom: 45px;
  @media (min-width: 915px) {
    bottom: 50px;
    gap: 15px;
  }
`;
const VideoView = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  
  @media (width: 1024px) and (height: 1366px){
    margin-top:8%;
    height: 35vh!important;
  }
  @media (width: 717px) and (height: 512px){
    height: 50vh!important;
  }
  @media (max-width: 615px) {
    height: 30vh;
  }
  @media (min-width: 616px) {
    height: 40vh;
  }
  @media (min-width: 912px) {
    margin-top:5%;
    height: 55vh;
  }
  
  ${(props) =>
    css`
      -webkit-transform: ${!props.currentDeviceIndex ? 'scaleX(-1)' : ''};
      transform: ${!props.currentDeviceIndex ? 'scaleX(-1)' : ''};
  }`}
`;
const TopLogo = styled.div`
  display: flex;
  position: absolute;
  // width: 90%;
  bottom: 1%;
  left: 1%;
  gap: 5px;
  @media (max-width: 918px) {
    display:none;
  }
`;
const SideView = styled.div`
  // border: 2px solid #1a5669;
  border-radius: 10px;
  margin: 100px 30px 10px 10px;
  height: 84.5%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  @media (max-width: 915px) {
    width: 80%;
    gap: 1em;
    margin: 0px 30px 10px 10px;
  }
`;
const MainTitle = styled.h1``;
const MethodeView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vh;
  width: 80%;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const StartNowView = styled.div`
  display: flex;
  width: 80%;
  gap: 10px;
`;
const InputGroupView = styled.div`
  display: flex;
  flex-direction: column;
  // gap:20px;
  width: 80%;
`;
const InputDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  width: 100%;
`;
const InputView = styled.input`
  ${(props) =>
    css`
      width: 100%;
      padding: 10px 10px 10px 35px;
      border-radius: 10px;
      border: 2px solid ${props.color};
      color: ${props.color};
      outline: none;
      &:focus-visible {
        border: 2px solid ${props.color};
      }
      @media (max-width: 915px) {
        font-size: 14px;
      }
    `}
`;

const InputLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 915px) {
    font-size: 16px;
  }
`;
const Text = styled.div`
  font-size: 20px;
`;
const RoomPasswordView = styled.div`
  display: flex;
  // flex-direction:column;
  width: 100%;
`;
const Styles = {
  MainTitle,
  Spinner,
  DeviceAlert,
  LoadingSpinIcon,
  MainContainer,
  RoomPasswordView,
  VideoView,
  VideoDiv,
  SideView,
  ChipButton,
  MethodeView,
  StartNowView,
  InputGroupView,
  InputView,
  InputDiv,
  Text,
  InputLabel,
  CallOptionButton,
  VideoOnIcon,
  MicOnIcon,
  VideoOffIcon,
  MicOffIcon,
  FloatingButton,
  BodyContainer,
  TopLogo,
  ToggleCameraIcon
};

export default Styles;
