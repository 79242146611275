import { createSlice } from "@reduxjs/toolkit";
import joinCallActions, {
  getVideoStream,
  toggleVideoTracks,
  toggleAudioTracks,
} from "./actions";

const initialState = {
  uiState: {
    streamLoading: true,
    streamError: null,
    cameraOn: false,
    audioOn: false,
    waitingCamera: false,
    audioRequiredMessage: false,
    cameraRequiredMessage: false,
  },
};

export const joinCallSlice = createSlice({
  name: "joinCallSlice",
  initialState: initialState,
  reducers: joinCallActions,
  extraReducers: {
    [getVideoStream.pending]: (state, _) => {
      state.uiState.streamLoading = true;
    },
    [getVideoStream.rejected]: (state, payload) => {
      state.uiState.streamLoading = false;
      state.uiState.streamError = payload.error.message;
    },
    [getVideoStream.fulfilled]: (state, { payload: { isAudio, isCamera } }) => {
      state.uiState.streamLoading = false;
      state.uiState.audioOn = isAudio;
      state.uiState.cameraOn = isCamera;
      state.uiState.cameraRequiredMessage = !isCamera;
      state.uiState.audioRequiredMessage = !isAudio;
    },
    [toggleVideoTracks.pending]: (state, _) => {
      state.uiState.waitingCamera = true;
    },
    [toggleVideoTracks.rejected]: (state, payload) => {},
    [toggleVideoTracks.fulfilled]: (state, { payload: { cameraOn } }) => {
      state.uiState.cameraOn = cameraOn;
      state.uiState.waitingCamera = false;
    },
    [toggleAudioTracks.pending]: (state, _) => {},
    [toggleAudioTracks.rejected]: (state, payload) => {},
    [toggleAudioTracks.fulfilled]: (state, { payload: { audioOn } }) => {
      state.uiState.audioOn = audioOn;
    },
  },
});

export default joinCallSlice.reducer;
