import { Modal } from 'antd';
import React, { useState } from 'react'


const KickParticipantModal = (props) => {
    console.log(props)
    const {SocketConnection , setOpenKickParticipantModal , openKickParticipantModal , name , memberId} = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleOk = () => {
        SocketConnection.KickUser(memberId)
        setConfirmLoading(true);
        setTimeout(() => {
            setOpenKickParticipantModal(false);
            setConfirmLoading(false);
        }, 500);
    };
    const handleCancel = () => {
        setOpenKickParticipantModal(false);
    };
    return (
        <Modal
            title="Remove User"
            open={openKickParticipantModal}
            onOk={handleOk}
            okText="Remove User"
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <p>Do you want to remove {name} from this call ?</p>
        </Modal>
    )
}


export default KickParticipantModal;