import { createContext, useReducer } from "react";

const MyStreams = (function () {
  let instance;
  let screenShare;
  let videoDevices = [];
  return {
    getInstance: async function () {
      const videoInputs = await navigator.mediaDevices.enumerateDevices();
      videoDevices = videoInputs.filter(device => device.kind === 'videoinput');

      if (instance) return instance;
      const defaultDeviceId = videoDevices[0]?.deviceId;
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: defaultDeviceId },
        audio: true,
      });

      const videoTracks = stream.getVideoTracks();
      instance = {
        audioStream: new MediaStream(stream.getAudioTracks()),
        videoStream: new MediaStream(videoTracks),
        isAudio: true,
        isCamera: true,
        deviceId:defaultDeviceId
      };

      // Add videoStream2 key if there are exactly 2 video devices
      // if (videoDevices.length === 2) {
      //   instance['videoStream2'] = null;
      // }

      return instance;
    },
    getScreenShare: async function () {
      if (screenShare) return { screenShare };
      const constraints = { video: { cursor: "always" }, audio: false };
      screenShare = await navigator.mediaDevices.getDisplayMedia(constraints);
      return { screenShare };
    },
    deleteScreenShare: async function () {
      await screenShare.getTracks().forEach((track) => {
        track.stop();
      });
      screenShare = null;
    },
    switchCamera: async function (index) {
      if (!instance || !videoDevices[index]) return instance;

      instance.videoStream.getTracks().forEach(t => {
        t.stop();
        instance.videoStream.removeTrack(t);
     });

      const deviceId = videoDevices[index].deviceId;
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId },
        audio: true,
      });

      const videoTracks = stream.getVideoTracks();
      // if (videoDevices.length === 2) {
      //   instance.videoStream2 = new MediaStream(videoTracks);
      // } else {
      // }
      instance.videoStream = new MediaStream(videoTracks);
      instance.deviceId = deviceId

      // Return the updated instance after switching camera
      return { ...instance };
    },
  };
})();

export default MyStreams;
