import { getTurnServer } from "../api/Api";
import { roomSlice } from "../../features/call/store/slice";
export default class PeerConnection {
  #Connections = {};
  #iceServers = {
    iceServers: null,
  };
  constructor() {}
  async setIceServers() {
    this.#iceServers.iceServers = await getTurnServer();
  }
  async #createConnection(userId, socketId, localStream) {
    this.#Connections = {
      ...this.#Connections,
      [userId]: {
        ...this.#Connections[userId],
        mediaConnection: new RTCPeerConnection(this.#iceServers),
        socketId,
      },
    };
    if (localStream.video) {
      // console.log("video",localStream.video)
      localStream.video.getTracks().forEach((track) => {
        this.#Connections[userId].mediaConnection.addTrack(
          track,
          localStream.video
        );
      });
    }else
    {
      this.#Connections[userId].mediaConnection.addTransceiver('video', {
        direction: 'recvonly',
      });
    }
    if (localStream.audio) {
      // console.log("audio",localStream.audio)
      localStream.audio.getTracks().forEach((track) => {
        this.#Connections[userId].mediaConnection.addTrack(
          track,
          localStream.audio
        );
      });
    }else
    {
      this.#Connections[userId].mediaConnection.addTransceiver('audio', {
        direction: 'recvonly',
      });
    }
  }
  async createOffer({
    currentUserName,
    newUserName,
    newUserSocketId,
    newUserId,
    currentUser,
    currentUserSocket,
    roomId,
    myStream,
    dispatch,
    updateStream,
    newUserEmail,
    currentUserEmail,
    newUserAudio,
    newUserCamera,
    currentUserAudio,
    currentUserCamera,
  }) {
    // console.log("me:",currentUser,"new:",newUserId)
    await this.#createConnection(newUserId, newUserSocketId, myStream);

    const offer = await this.#Connections[
      newUserId
    ].mediaConnection.createOffer();
    await this.#Connections[newUserId].mediaConnection.setLocalDescription(
      offer
    );
    // console.log("first")
    dispatch(
      roomSlice.actions.addUserData({
        userName:newUserName,
        email:newUserEmail,
        userId:newUserId,
        socketId:newUserSocketId,
      })
    );
    this.addVideoTrack({
      updateStream,
      userId: newUserId,
      dispatch,
      audioOn: newUserAudio,
      cameraOn: newUserCamera,
    });

    return {
      mediaConnection: this.#Connections[newUserId].mediaConnection,
      payload: {
        senderUsername:currentUserName,
        receiverUserName:newUserName,
        senderEmail: currentUserEmail,
        sender: currentUser,
        receiver: newUserId,
        sdp: offer,
        roomId: roomId,
        senderSocketId: currentUserSocket,
        receiverSocketId: newUserSocketId,
        senderAudioOn: currentUserAudio,
        senderCameraOn: currentUserCamera,
      },
    };
  }
  async createAnswer({
    senderUsername,
    receiverUserName,
    sender,
    receiver,
    senderSocketId,
    receiverSocketId,
    roomId,
    sdp,
    myStream,
    dispatch,
    updateStream,
    senderEmail,
    senderAudioOn,
    senderCameraOn,
  }) {
    // console.log("me:",receiver,"new:",sender,sdp)
    await this.#createConnection(sender, senderSocketId, myStream);

    this.addVideoTrack({
      updateStream,
      userId: sender,
      dispatch,
      audioOn: senderAudioOn,
      cameraOn: senderCameraOn,
    });
    // console.log("second")
    dispatch(
      roomSlice.actions.addUserData({
        userName:senderUsername,
        email:senderEmail,
        userId:sender,
        socketId:senderSocketId,
      })
    );

    await this.#Connections[sender].mediaConnection.setRemoteDescription(sdp);
    const answer = await this.#Connections[sender].mediaConnection.createAnswer(
      sdp
    );
    await this.#Connections[sender].mediaConnection.setLocalDescription(answer);

    return {
      mediaConnection: this.#Connections[sender].mediaConnection,
      payload: {
        sender: receiver,
        receiver: sender,
        sdp: answer,
        roomId: roomId,
        senderSocketId: receiverSocketId,
        receiverSocketId: senderSocketId,
      },
    };
  }
  async addVideoTrack({ updateStream, userId, dispatch,audioOn,cameraOn }) {
    this.#Connections[userId].mediaConnection.ontrack = async (event) => {
      if (event.track.kind == "video") {
        updateStream.addStreams({
          userId: userId,
          stream: event.streams[0],
          type: "video",
        });
        dispatch(
          roomSlice.actions.addUserVideo({
            userId,
            video:cameraOn
          })
        );
      } else if (event.track.kind == "audio") {
        updateStream.addStreams({
          userId: userId,
          stream: event.streams[0],
          type: "audio",
        });
        dispatch(
          roomSlice.actions.addUserAudio({
            userId,
            audio:audioOn
          })
        );
      }
    };
  }
  async setRemoteDescription({ sdp, sender }) {
    // console.log("new:",sender,sdp)
    await this.#Connections[sender].mediaConnection.setRemoteDescription(sdp);
  }
  async #getRTCIce(candidate) {
    return new RTCIceCandidate(candidate);
  }
  async addCandidates({ candidate, sender }) {
    if (!candidate) return console.log("erro in adding iceCandidates");
    await this.#Connections[sender].mediaConnection.addIceCandidate(
      await this.#getRTCIce(candidate)
    );
  }

  // async onEventReplace({ userId, dispatch }) {
  //   const pc = this.#Connections[userId].mediaConnection;
  //   const sender = await pc.getSenders();
  //   console.log("found reciver:", sender);
  //   sender.onreplacetrack = () => {
  //     console.log("☺☺☺ EVENTCMInG ☺☺☺");
  //     dispatch(generalSlice.actions.screenShareOn({ userId }));
  //   };
  // }
  async startVideoStream({ newStream }) {
    if (Object.keys(this.#Connections).length === 0) return;

    const videoTrack = newStream.getVideoTracks()[0];
    for (const userId in this.#Connections) {
      const pc = this.#Connections[userId].mediaConnection;
      var sender = pc.getSenders().find(function (s) {
        return s.track.kind == videoTrack.kind;
      });
      sender.replaceTrack(videoTrack);
    }
  }
  // async screenShare({userId,stream})
  // {
  // 	const pc=this.#Connections[
  // 		userId
  // 	].mediaConnection;
  // 	const videoTrack=stream.getVideoTracks()[0];
  // 	var sender = pc.getSenders().find(function(s) {
  // 		return s.track.kind == videoTrack.kind;
  // 	});
  // 	console.log('found sender:', sender);
  // 	sender.replaceTrack(videoTrack);
  // }
  async createPeerConnection_screenShare({
    receiver,
    stream,
    screenShare = false,
  }) {
    this.#Connections = {
      ...this.#Connections,
      [receiver]: {
        ...this.#Connections[receiver],
        screenShareConnection: new RTCPeerConnection(this.#iceServers),
      },
    };
    if (screenShare){
      // this.#Connections[receiver].screenShareConnection.addTransceiver('video', {
      //   direction: 'recvonly',
      // });
      return;
    }

    stream.getTracks().forEach((track) => {
      this.#Connections[receiver].screenShareConnection.addTrack(track, stream);
    });
  }
  async createOffer_screenShare({
    userId,
    socketId,
    stream,
    roomId,
    receiver,
    receiverSocketId,
  }) {

    await this.createPeerConnection_screenShare({
      receiver,
      stream,
    });
    const offer = await this.#Connections[
      receiver
    ].screenShareConnection.createOffer();
    await this.#Connections[receiver].screenShareConnection.setLocalDescription(
      offer
    );
    return {
      screenShareConnection: this.#Connections[receiver].screenShareConnection,
      payload: {
        sender: userId,
        senderSocketId: socketId,
        sdp: offer,
        roomId: roomId,
        receiver,
        receiverSocketId,
      },
    };
  }
  async createAnswer_screenShare({
    receiver,
    sender,
    senderSocketId,
    offer,
    streams,
    receiverSocketId,
    dispatch,
  }) {
    await this.createPeerConnection_screenShare({
      receiver,
      screenShare: true,
    });
    this.addVideoTrack_screenShare({
      sender,
      receiver,
      streams,
      dispatch,
    });
    await this.#Connections[
      receiver
    ].screenShareConnection.setRemoteDescription(offer);
    const answer = await this.#Connections[
      receiver
    ].screenShareConnection.createAnswer(offer);
    await this.#Connections[receiver].screenShareConnection.setLocalDescription(
      answer
    );

    return {
      screenShareConnection: this.#Connections[receiver].screenShareConnection,
      payload: {
        sender,
        receiver,
        sdp: answer,
        senderSocketId,
        receiverSocketId,
      },
    };
  }
  async addVideoTrack_screenShare({ sender,receiver, streams, dispatch }) {
    this.#Connections[receiver].screenShareConnection.ontrack = async (
      event
    ) => {
      if (event.track.kind == "video") {
        await streams.addStreams({
          userId: receiver,
          stream: event.streams[0],
          type: "video",
          screenShare: true,
        });
        dispatch(roomSlice.actions.addScreenShare({userId:receiver}));
        dispatch(roomSlice.actions.startScreenShare({userId:receiver}));
      }
    };
  }
  async screenShare_addCandidates({ candidate, sender }) {
    if (!candidate) return console.log("erro in adding iceCandidates");
    await this.#Connections[sender].screenShareConnection.addIceCandidate(
      await this.#getRTCIce(candidate)
    );
  }
  async screenShare_setRemoteDescription({ sdp, sender }) {
    await this.#Connections[sender].screenShareConnection.setRemoteDescription(
      sdp
    );
  }
  async closeConnection({userId}) {
    if(this.#Connections[userId].mediaConnection)
    {
      this.#Connections[userId].mediaConnection.close();
    }
    if(this.#Connections[userId].screenShare)
    {
      this.#Connections[userId].screenShare.close();
    }
    delete this.#Connections[userId];
  }
  async closeAllConnection()
  {
    for(let key in this.#Connections)
    {
      if(!this.#Connections[key])
        continue;

      if(this.#Connections[key].mediaConnection)
      {
        this.#Connections[key].mediaConnection.close();
      }
      if(this.#Connections[key].screenShare)
      {
        this.#Connections[key].screenShare.close();
      }
      delete this.#Connections[key];
    }
  }
  async deleteScreenShare({ userId }) {
    delete this.#Connections[userId].screenShareConnection;
  }
}
