import React, { useState } from 'react'
import { Button, Modal } from 'antd';
import { roomSlice } from '../../store/slice';
import {
    updateScheduleMeetingTime,
    updateScheduleMeetingTimeByExternal,
    updateScheduleMemberMeetingTime,
    updateScheduleMemberMeetingTimeByExternal
} from '../../../../data/api/Api';


const EndOrLeaveModal = (props) => {
    const { useSelector, SocketConnection, dispatch, navigate, userId, adminIds } = props;
    const email = useSelector(
        ({ generalSlice }) => generalSlice.email
    );

    const showEndMeetingModal = useSelector(
        ({ roomSlice: { uiState } }) => uiState.showEndMeetingModal
    );
    const [confirmLoading, setConfirmLoading] = useState(true);

    const accessToken = useSelector(
        ({ generalSlice }) => generalSlice.accessToken
    );
    const scheduleId = useSelector(
        ({ generalSlice }) => generalSlice.scheduleId
    );
    const userIds = useSelector((state) => state.roomSlice.userIds);

    const updateScheduleMeetingEnded = async () => {
        if (scheduleId) {
            const response = accessToken ?
                await updateScheduleMemberMeetingTime(accessToken, scheduleId, false) :
                await updateScheduleMemberMeetingTimeByExternal(scheduleId, false, email);
            if (accessToken) {
                console.log("updateScheduleMemberMeetingTime", response);
            }
            else {
                console.log("updateScheduleMemberMeetingTimeByExternal", response);
            }
            if (userIds.length === 1 && accessToken) {
                // const response = await updateScheduleMeetingTime(accessToken, scheduleId, false);
                // console.log("updateScheduleMeetingTime when only last user in call", response);
            }
            else if (userIds.length === 1 && !accessToken) {
                // const response = await updateScheduleMeetingTimeByExternal(scheduleId, false, email);
                // console.log("updateScheduleMeetingTimeByExternal when only last user in call", response);
            }
        }
    }
    const handleEndMeeting = () => {
        // console.log("handleEndMeeting CLICKED");
        updateScheduleMeetingEnded();
        dispatch(roomSlice.actions.updateModalView(!showEndMeetingModal));
        SocketConnection.EndCallForAll(adminIds[0]);
        navigate("/leave-ended");
        return
    }
    const handleLeaveMeeting = () => {
        updateScheduleMeetingEnded();
        dispatch(roomSlice.actions.updateModalView(!showEndMeetingModal));
        SocketConnection.LeaveCall();
        navigate("/leave");
        window.close();
    }
    const handleCancel = () => {
        dispatch(roomSlice.actions.updateModalView(!showEndMeetingModal));
        return;
    };
    const buttons = [
        <Button style={{ margin: "2px" }} key="3" onClick={handleCancel}>Cancel</Button>,
        <Button style={{ margin: "2px" }} key="1" onClick={handleLeaveMeeting} type="primary">Leave Meeting</Button>
    ]
    return (
        <Modal
            title={adminIds?.includes(userId) ? "End or leave Meeting" : "Leave Meeting"}
            open={showEndMeetingModal}
            onCancel={handleCancel}
            confirmLoading={confirmLoading}
            footer={
                adminIds?.includes(userId) ?
                    [
                        ...buttons,
                        <Button style={{ margin: "2px" }} key="2" onClick={handleEndMeeting} type="primary">End Meeting</Button>
                    ] :
                    buttons
            }
        >
            {adminIds?.includes(userId) ? " Are you sure you want to end or leave the meeting ?" : " Are you sure you want to leave the meeting ?"}

        </Modal>
    );
}


export default EndOrLeaveModal;