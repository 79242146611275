import Styles from "../styles/grid";
import Styles2 from "../styles/Call.styles";
import React, { useRef, useEffect, useContext } from "react";
import { BsPinAngle, BsPin } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { roomSlice } from "../../store/slice";
import { BaseContext } from "../../../../app/BaseConext";
import { Tooltip } from "antd";


const ScreenShareComponent = ({ userId, style, sidebarlayout }) => {
  const { SocketConnection } = useContext(BaseContext);
  const dispatch = useDispatch();
  const videoStream = useRef();
  const screenType = useRef();

  const {
    VideoStreamTag,
    CircleDiv,
    VideoText,
    NotVideoStreamTag,
    PinTag,
    VideoStreamParentdiv,
  } = Styles;
  const { ButtonView2 } = Styles2;

  const isPin = useSelector(({ roomSlice }) => {
    return roomSlice.users[userId]?.uiState?.pin;
  });
  const viewType = useSelector(({ roomSlice }) => {
    return roomSlice.uiState.viewType;
  });
  // console.log("USER ID: " + )
  const email = useSelector(({ roomSlice }) => roomSlice.users[userId.split("_")[0]]?.email);

  useEffect(() => {
    screenType.current =
      viewType === 1
        ? 8
        : viewType === 2
          ? 6
          : viewType === 3
            ? 4
            : viewType === 4
              ? 4
              : 8;
    // console.log("child screen share component - useEffect - view type");
  }, [viewType]);

  useEffect(() => {
    let mount = true;
    if (mount) {

      SocketConnection.Streams.getUserStreams(userId)
        .then(async ({ video }) => {
          if (video) {
            videoStream.current.srcObject = video;
          }
        })
        .catch((error) => { });
    }
    return () => {
      mount = false;
    };
  }, [videoStream]);
  function handlePinClick() {
    dispatch(roomSlice.actions.updatePinCount({ isPin: !isPin }));
    dispatch(roomSlice.actions.setPin({ userId: userId, isPin: !isPin }));
  }
  return (
    <>
      <VideoStreamParentdiv>
        <VideoStreamTag
          style={style}
          screenShare={true}
          ref={videoStream}
          autoPlay
        />
        <Tooltip title={email}>
          <PinTag className="pinBoxVideo">
            <ButtonView2 onClick={handlePinClick}>
              {isPin ? (
                <BsPin size="20px" color="white" />
              ) : (
                <BsPinAngle size="20px" color="white" />
              )}
            </ButtonView2>
          </PinTag>
        </Tooltip>

      </VideoStreamParentdiv>
    </>
  );
};
export default ScreenShareComponent;
