const SOCKET_STATE = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  RECONNECTING: "reconnecting",
};

function socketConnecting(state, { payload }) {
  return {
    ...state.socketConnectionState,
    connectionState: SOCKET_STATE.CONNECTING,
  };
}

function socketConnected(state, { payload }) {
  return {
    ...state.socketConnectionState,
    isLoading: false,
    connectionState: SOCKET_STATE.CONNECTED,
  };
}
function socketDisConnecting(state, { payload }) {
  return {
    ...state.socketConnectionState,
    isLoading: true,
    connectionState: SOCKET_STATE.DISCONNECTED,
    error: payload.error,
  };
}
function socketReconnecting(state, { payload }) {
  return {
    ...state.socketConnectionState,
    connectionState: SOCKET_STATE.RECONNECTING,
    error: payload.error,
  };
}

function onStreamAdd() {}

const socketSliceAction = {
  onStreamAdd,
  socketConnecting,
  socketDisConnecting,
  socketConnected,
  socketReconnecting,
};
export default socketSliceAction;
