
import { useEffect, useState } from "react";
import Styles from "../styles/grid";
import { FaLock } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import "../styles/index.css";
import WorkWsieIcon from "../../../ResultComponent/WorkWsieIcon";

function BottomLeftComponent() {
    const { BottomLeftDiv, Text, Time } = Styles;
    const ismobile = useMediaQuery({ maxWidth: 500 });
    const isSmallmobile = useMediaQuery({ maxWidth: 350 });
    const [date, setDate] = useState('00:00:00');

    useEffect(() => {
        let seconds = 0;
        const interval = setInterval(() => {
            seconds++;

            const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
            const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
            const remainingSeconds = (seconds % 60).toString().padStart(2, '0');

            // Format time based on duration
            let currentTime = `${minutes}:${remainingSeconds}`;
            if (seconds >= 3600) {
                currentTime = `${hours}:${minutes}:${remainingSeconds}`;
            }

            setDate(currentTime);
        }, 1000); // 1 second

        return () => {
            clearInterval(interval);
        };
    }, []);

    const isLock = useSelector(
        ({ roomSlice: { uiState } }) => uiState.isLock
    );
    const { userId, adminIds } = useSelector(
        ({ generalSlice }) => generalSlice
    );
    return (
        <>
            <BottomLeftDiv>
                {
                    isSmallmobile ?
                        <div style={{ wordSpacing: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                            {
                                isLock ?
                                    <>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <div style={{ marginRight: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                <WorkWsieIcon height="25" width="35" logoOnly={true} />
                                                {
                                                    !adminIds?.includes(userId) && <FaLock size={11} />
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <WorkWsieIcon height="20" width="50" logoOnly={true} />
                                        <Time>{date}</Time>
                                    </div>
                            }


                        </div>
                        :
                        !ismobile ?
                            <Text>
                                <WorkWsieIcon height="30" width="50" logoOnly={true} />
                                <div>
                                    {date}
                                    {isLock && <FaLock style={{ marginLeft: "5px" }} size={14} />}
                                </div>
                            </Text>
                            :
                            <div style={{ wordSpacing: "0px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                {
                                    isLock ?
                                        <>
                                            <div></div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                    <WorkWsieIcon height="20" width="50" logoOnly={true} />
                                                    <FaLock size={11} />
                                                </div>
                                                {date}

                                            </div>
                                        </>
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <WorkWsieIcon height="20" width="50" logoOnly={true} />
                                            <Time>{date}</Time>
                                        </div>
                                }

                            </div>
                }

            </BottomLeftDiv>
        </>

    );
}
export default BottomLeftComponent;