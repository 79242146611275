import { useSelector } from "react-redux";
import { capitalizeWordsInArray } from "../../../../app/helper";
import { AddScheduleExternalMemberAPI, AddScheduleMemberAPI, addParticipantsAPI, checkRoom, getAllUsersRefrenceAPI } from "../../../../data/api/Api";
import { Button, Modal, Select } from "antd";
import { useLocation } from "react-router-dom";
import { useState } from "react";


function AddPartcipantModal(props) {
    const { showAddParticipantsModal, setShowAddParticipantsModal, messageApi } = props
    const { userId, email, adminIds, accessToken } = useSelector(
        ({ generalSlice }) => generalSlice
    );
    const search = useLocation().search;
    const roomId = new URLSearchParams(search).get("room");
    const error = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [options, setOptions] = useState([]);
    const [addParticipantsAPIUsers, setAddParticipantsAPIUsers] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCancelAddParticipants = () => {
        setShowAddParticipantsModal(false)
        setSelectedParticipants([]);
    }
    const handleSelectChangeAddparticipant = (options) => {

        let finalArray = []
        for (let i = 0; i < options.length; i++) {
            const element = options[i].trim().toLowerCase();
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (emailRegex.test(element)) {
                const filterUser = addParticipantsAPIUsers.find((user) => {
                    return user.email === element
                })
                filterUser ? finalArray.push(filterUser.name) : finalArray.push(element)
            }
            else {
                finalArray.push(element)
            }
        }
        console.log(finalArray, "------------------------")
        const uniqueSet = new Set(capitalizeWordsInArray(finalArray));
        const uniqueArray = Array.from(uniqueSet)
        setSelectedParticipants(uniqueArray)

    };
    const handleAddParticipants = async () => {

        setConfirmLoading(true);
        console.log("confirm1", confirmLoading)
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const uuids = [];
        const emails = [];
        if (!selectedParticipants.length) {
            error("Please select participants")
            setConfirmLoading(false);
            return
        }

        selectedParticipants.forEach((element) => {
            if (emailRegex.test(element)) {
                emails.push(element);
            } else {
                const filterUser = addParticipantsAPIUsers.find((user) => {
                    return user.name === element
                })
                if (filterUser) {
                    uuids.push(filterUser.id)
                } else error(`${element} Does Not Exist or Invalid`);
            }
        });

        const body = {
            "members": {
                "memberIds": uuids,
                "outSiderEmails": emails
            },
            "meetingId": roomId,
            "isVideo": false,
            "roomType": "1"

        }
        const { scheduleId } = await checkRoom(roomId);
        if (uuids.length && scheduleId && accessToken) {
            let uuidObjects = []
            uuidObjects = uuids.map((uuid) => {
                return {
                    "memberId": uuid,
                    "memberType": 2,
                    "isExternal": false
                }
            })
            const response = await AddScheduleMemberAPI(accessToken, uuidObjects, scheduleId);
            console.log(response, "RESPONSE1");
            if (response.message == 'success' || response[0].status) {
                success("Workwise participants have been added to schedule")
            }

        }
        if (scheduleId && accessToken && emails.length) {
            const response = await AddScheduleExternalMemberAPI(accessToken, emails, scheduleId);
            console.log(response, "RESPONSE2");
            if (response.message == 'success' || response[0].status) {
                success("External participants have been added to schedule")
            }
        }

        const response = await addParticipantsAPI(body, accessToken)
        console.log(response, "addParticipantsAPI RESPONSE2");

        setConfirmLoading(false);
        console.log("confirm2", confirmLoading)
        setShowAddParticipantsModal(false)
        setSelectedParticipants([]);
        return
    }
    const updateParticipantsList = async () => {
        if (adminIds?.includes(userId) && userId) {
            setLoading(true)
            const users = await getAllUsersRefrenceAPI(accessToken);
            setAddParticipantsAPIUsers(users);
            const tempOptions = []
            if (users.length > 0) {
                users.map(user => {
                    tempOptions.push({
                        value: user.name,
                        label: user.name,
                    });
                })
            }
            setOptions(tempOptions);
            setLoading(false)
        }
    }

    return (
        <Modal
            title={"Add Participants to the call"}
            open={showAddParticipantsModal}
            onCancel={() => handleCancelAddParticipants()}
            confirmLoading={confirmLoading}
            onOk={() => handleAddParticipants()}
            okText={"Add participants"}
        >
            <Select
                mode="tags"
                size={"large"}
                placeholder="Please select"
                loading={loading}
                defaultValue={[]}
                onChange={handleSelectChangeAddparticipant}
                onFocus={updateParticipantsList}
                style={{
                    width: '100%',
                }}
                allowClear={true}
                autoClearSearchValue={true}
                options={options}
                value={selectedParticipants}
            />
        </Modal>
    )
}
export default AddPartcipantModal