import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateAudioTrack } from "../../../domain/actions/ToggleAudioTrack";
import { updateVideoTrack } from "../../../domain/actions/ToggleVideoTrack";
export const getVideoStream = createAsyncThunk(
  "joinCallSlice/getVideoStream",
  async (state, { rejectWithValue }) => {
    return await state.getInstance().then(({ isAudio, isCamera }) => {
      return { isAudio, isCamera };
    });
  }
);

export const toggleAudioTracks = createAsyncThunk(
  "joinCallSlice/toggleAudioTracks",
  async (mediaStream, { getState }) => {
    const state = getState();
    return await updateAudioTrack(state.joinCallSlice, mediaStream);
  }
);

export const toggleVideoTracks = createAsyncThunk(
  "joinCallSlice/toggleVideoTracks",
  async (data, { getState }) => {
    const state = getState();
    return await updateVideoTrack(state.joinCallSlice, data);
  }
);

const joinCallActions = {};

export default joinCallActions;
