
import Styles from "../styles/grid";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import { Switch } from 'antd';
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { generalSlice } from "../../../../domain/SharedSlices/generalslice/generalSlice";
import { updateRoom } from "../../../../data/api/Api";
import { roomSlice } from "../../store/slice";
import { Button, message } from 'antd';
import { BaseContext } from "../../../../app/BaseConext";

function SidebarInfo(props) {
  const dispatch = useDispatch();
  const {
    SidebarInfoBody,
    Sidebar,
    SidebarHeading,
    SidebarHeader,
    SidebarHeaderButton,
    SidebarHR,
    SidebarInfoBodyHeader,
    SidebarInfoBodyButton,
  } = Styles;
  const { onClickSidebarClose } = props;
  const search = useLocation().search;
  const roomId = new URLSearchParams(search).get("room");
  const isGalaxyFolded = useMediaQuery({ maxWidth: 290 });
  const [disableSwitch, setDisableSwitch] = useState(false);
  const adminIds = useSelector(
    ({ generalSlice }) => generalSlice?.adminIds
  );
  const userId = useSelector(
    ({ generalSlice }) => generalSlice.userId
  );
  const isLock = useSelector(
    ({ roomSlice: { uiState } }) => uiState.isLock
  );
  const accessToken = useSelector(
    ({ generalSlice }) => generalSlice.accessToken
  );
  const { SocketConnection } = useContext(BaseContext);
  const handleLockSwitch = async () => {
    setDisableSwitch(true);
    dispatch(roomSlice.actions.updateRoomLock({ isLock: !isLock }))
    const data = { isLock: !isLock }
    const response = await updateRoom(roomId, data, accessToken)
    if (!response.data.updated) {
      alert("Unexpected Error in Locking Room ");
      dispatch(roomSlice.actions.updateRoomLock({ isLock: !isLock }))
    }
    SocketConnection.NotifyRoomLockUpdate(data)
    setDisableSwitch(false);
  }
  const [messageApi, contextHolder] = message.useMessage();
  // console.log(messageApi, contextHolder)
  const success = () => {

    const URL = window.location.origin + "/" + roomId;
    navigator.permissions
      .query({
        name: "clipboard-write",
      })
      .then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(URL).then(
            () => {
              messageApi.open({
                type: 'success',
                content: 'You have successfully copied the URL',
                duration: 3,
              });
            },
            () => {
              messageApi.open({
                type: 'Error',
                content: 'You were unsuccessful in copying the URL.',
                duration: 3,
              });
            }
          );
        } else {
          alert("Write access not granted!");
        }
      });
  };
  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarHeading> Meeting Details </SidebarHeading>
        <SidebarHeaderButton onClick={() => { onClickSidebarClose() }}>X</SidebarHeaderButton>
      </SidebarHeader>
      <SidebarHR style={{ marginTop: "0px" }} />
      <SidebarInfoBody>
        <SidebarInfoBodyHeader>Joining Link</SidebarInfoBodyHeader>
        <h4 style={{ color: "white", fontWeight: "100", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%", overflow: "hidden" }}>{window.location.origin + "/" + roomId}</h4>
        {/* <>
          <Button onClick={success}>Customized display duration</Button>
        </> */}

        {contextHolder}
        <SidebarInfoBodyButton onClick={success}>
          <IoCopyOutline />
          Copy Link
        </SidebarInfoBodyButton>

        {
          adminIds?.includes(userId) &&
          <>
            <hr style={{ marginTop: "20px", width: "100%", opacity: 0.2 }} />
            <div style={{ width: "100%", marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ color: "white", fontSize: isGalaxyFolded ? "16px" : "20px", fontWeight: "100" }}>
                Lock Meeting
              </div>
              <div>
                <Switch style={isLock ? { backgroundColor: "#1A5669" } : { color: "white" }} size={isGalaxyFolded ? "small" : "default"} checkedChildren="Locked" unCheckedChildren="Unlocked" checked={isLock} onChange={handleLockSwitch} disabled={disableSwitch} />
              </div>
            </div>
          </>
        }
      </SidebarInfoBody>
    </Sidebar>
  );
}


export default SidebarInfo;