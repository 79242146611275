import { roomSlice } from "../../features/call/store/slice";
export default class Streams {
  #streams = {};
  constructor() { }
  async addStreams({ userId, stream, type, screenShare = false }) {

    const streamId = screenShare ? userId + "_screen_share" : userId;
    this.#streams = {
      ...this.#streams,
      [streamId]: {
        ...this.#streams[streamId],
        [type]: stream,
      },
    };
  }
  async updateStream(userId, newStream, type) {
    if (!this.#streams[userId]) {
      console.error(`User with ID ${userId} not found in streams.`);
      return;
    }
    
    if (this.#streams[userId][type]) {
      console.log(`Stopping tracks for user ${userId} - ${type}`);
      this.#streams[userId][type].getTracks().forEach((track) => {
        track.stop();
      });
    }

    // Update the local stream reference
    this.#streams[userId] = {
      ...this.#streams[userId],
      [type]: newStream,
    };

  }
  async deleteAllStream() {
    for (let key in this.#streams) {
      if (this.#streams.hasOwnProperty(key)) {
        if (this.#streams[key].video) {
          await this.#streams[key].video.getTracks().forEach((track) => {
            track.stop();
          });
        }
        if (this.#streams[key].audio) {
          await this.#streams[key].audio.getTracks().forEach((track) => {
            track.stop();
          });
        }
        delete this.#streams[key];
      }
    }
  }
  async deleteStream({ userId, dispatch, screenShare = false }) {
    const streamId = screenShare ? userId + "_screen_share" : userId;

    dispatch(roomSlice.actions.deleteUser({ userId: streamId }));
    dispatch(roomSlice.actions.deleteUserUi({ userId: streamId }));
    if (!screenShare && this.#streams[streamId].audio) {
      await this.#streams[streamId].audio.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.#streams[streamId].video) {
      await this.#streams[streamId].video.getTracks().forEach((track) => {
        track.stop();
      });
    }
    await this.#deleteInstace(streamId);

  }
  async #deleteInstace(streamId) {
    delete this.#streams[streamId];
  }
  async getUserStreams(userId) {
    if (!this.#streams[userId]) return false;
    return await this.#streams[userId];
  }
  async getStreams() {
    return this.#streams;
  }
}
