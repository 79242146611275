import React, { useEffect } from "react";
import WorkWsieIcon from "./WorkWsieIcon";
import { useMediaQuery } from "react-responsive";
import { BiCopyright } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function ResultComponent({ title, reconnect = false }) {

  const ismobile = useMediaQuery({ maxWidth: 500 });

  const navigate = useNavigate();

  return (

    <div style={styles.container} >
      {
        ismobile ?
          <WorkWsieIcon height="140" width="150" /> :
          <WorkWsieIcon />
      }
      <a style={ismobile ? styles.smallText : styles.textAnchor} href="https://www.workw.com">
        {"www.workw.com"}
      </a>
      {
        reconnect &&
        <a onClick={() => navigate(-1)} style={ismobile ? styles.smallText : styles.textAnchor}>
          {"Reconnect call"}
        </a>
      }
      <div style={ismobile ? styles.smallText : styles.text}>
        {title}
      </div>
      <div style={styles.coprightContainer}>
        <BiCopyright /> Workwise - Copyrights @2016-2024
      </div>
    </div>
  );
}


const styles = {
  container: {
    backgroundColor: "black",
    width: "100vw",
    height: "100vh",
    // height:"inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  smallText: {
    marginTop: "0px",
    color: "white",
    fontSize: 15,
    textAlign: "center",
  },
  text: {
    marginTop: "20px",
    color: "white",
    fontSize: 25,
    textAlign: "center",
  },
  textAnchor: {
    textDecoration: "underline",
    // color: "blue",
    marginTop: "10px",
    // color: "white",
    fontSize: 20,
  },
  coprightContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    columnGap: "8px",
    bottom: "5px",
    // left: "10px",
    color: "white",
    fontSize: 13,
    position: "absolute",

  }
}
export default ResultComponent;