
import Styles from "../styles/grid";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { IoPersonAddSharp } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import { Button, message } from 'antd';
import SidebarMemberRowComponent from "./SidebarMemberRowComponent";
import { CapitalizeName, capitalizeWordsInArray } from "../../../../app/helper";
import AddPartcipantModal from "../modules/addParticipantModal";

function SidebarMembers(props) {

    const [members, setMembers] = useState([])
    const users = useSelector(({ roomSlice: { users } }) => users);
    const { userId, adminIds } = useSelector(
        ({ generalSlice }) => generalSlice
    );
    useEffect(() => {
        setMembers(Object.keys(users))
    }, [users])
    const [messageApi, contextHolder] = message.useMessage();

    const {
        SidebarHeaderRow,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
        SidebarMemberBody,
        Sidebar,
    } = Styles;
    const { onClickSidebarClose } = props;


    const [showAddParticipantsModal, setShowAddParticipantsModal] = useState(false);

    return (
        <Sidebar>
            {contextHolder}
            {showAddParticipantsModal ?
                <AddPartcipantModal
                    showAddParticipantsModal={showAddParticipantsModal}
                    setShowAddParticipantsModal={setShowAddParticipantsModal}
                    messageApi={messageApi}
                />
                : <></>
            }

            <SidebarHeader>
                <SidebarHeading> Participants </SidebarHeading>
                <SidebarHeaderButton onClick={() => { onClickSidebarClose() }}>X</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarHR style={{ marginTop: "0px" }} />

            {
                adminIds?.includes(userId) ?
                    <SidebarHeaderRow justify="center" style={{ display: "flex", flexDirection: "row" }} >
                        <Button type="primary" size={"large"}
                            onClick={() => setShowAddParticipantsModal(true)}
                            style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center", gap: "10px", width: "95%", border: "none"
                            }}>
                            <IoPersonAddSharp size="15px" />
                            <div >Add Participants</div>
                        </Button>
                        {/* <Button type="primary" size={"large"}
                            onClick={() => { }}
                            style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center", gap: "10px", width: "45%", border: "none"
                            }}>
                            <RiAdminFill size="17px" />
                            <div >Add Hosts</div>
                        </Button> */}
                    </SidebarHeaderRow>
                    : ""
            }

            {/* <SidebarHeaderRow justify="center">
                <SidebarHeaderInput type="text" placeholder="Search Participants" ></SidebarHeaderInput>
            </SidebarHeaderRow>  */}
            <SidebarMemberBody style={{ marginBottom: "15px" }}>
                {
                    members.map((memberId) => {
                        if (memberId.endsWith("_screen_share")) return;
                        return <SidebarMemberRowComponent
                            key={memberId}
                            memberId={memberId}
                            userId={userId}
                            name={CapitalizeName(users[memberId]?.userName)}
                            email={userId == memberId ? "You" : users[memberId]?.email}
                            admin={adminIds?.includes(memberId) ? true : false}
                            adminIds={adminIds || []}
                        />
                    })
                }
            </SidebarMemberBody>
        </Sidebar>
    );
}


export default SidebarMembers;