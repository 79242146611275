import React, { useEffect, useRef, useState } from "react";
import Styles from "../styles/grid";

import BottomLeftComponent from "./BottomLeftComponent";
import BottomMidComponent from "./BottomMidComponent";
import BottomRightComponent from "./BottomRightComponent";




function BottomDivMain(props) {
    
    const {BottomDiv} = Styles;
    const {openSidebar,openMobileSidebar } =props;
    return (
        <BottomDiv>
          <BottomLeftComponent/>
          <BottomMidComponent/>
          <BottomRightComponent openSidebar={openSidebar} openMobileSidebar={openMobileSidebar}/>
        </BottomDiv>
    )
}
export default BottomDivMain;


