import Styles from "../styles/grid";
import LayoutSVG from "../../../../assets/Layout.svg";
import Group2 from "../../../../assets/Group2.svg";
import poll from "../../../../assets/poll.svg";
import Path1 from "../../../../assets/Path1.svg";
import Path2 from "../../../../assets/Path2.svg";
import Transcibe from "../../../../assets/transcribe.svg";
import '../styles/index.css'
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { BaseContext } from "../../../../app/BaseConext";
import { checkUserRaiseHand } from "../../../../app/helper";
import { IoCameraReverseOutline } from "react-icons/io5";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import { copyURL } from "../../../../domain/modules/CopyMeetingUrl";


function MobileSidebar(props) {
    const { SocketConnection } = useContext(BaseContext)
    const {
        HeaderColText,
        SidebarShareRow,
        SidebarHeaderRow,
        Sidebar,
        SidebarHeading,
        SidebarHeader,
        SidebarHeaderButton,
        SidebarHR,
    } = Styles;

    const userIds = useSelector((state) => state.roomSlice.userIds);
    const pinCount = useSelector(
        ({ roomSlice: { uiState } }) => uiState.pinCount
    );
    const { onClickMobileSidebarClose, openSidebar } = props;

    const isHandRised = useSelector(({ roomSlice }) => {
        return roomSlice.uiState.isHandRised;
    });
    const myId = useSelector(
        ({ generalSlice }) => generalSlice.userId
    );
    const handRaisedIds = useSelector(
        ({ generalSlice }) => generalSlice.handRaisedIds
    );
    const handleHandRaised = () => {
        SocketConnection.NotifyRaiseHand(!isHandRised, handRaisedIds, myId)
    }
    const [messageApi, contextHolder] = message.useMessage();
    const search = useLocation().search;
    const roomId = new URLSearchParams(search).get("room");

    return (
        <Sidebar style={{ paddingBottom: "8px" }} >
            {contextHolder}
            <SidebarHeader>
                <SidebarHeading style={{ fontSize: "15px" }}> Menu Bar </SidebarHeading>
                <SidebarHeaderButton onClick={() => { onClickMobileSidebarClose() }}>x</SidebarHeaderButton>
            </SidebarHeader>
            <SidebarHR style={{ marginTop: "0px", marginBottom: "10px" }} />
            <SidebarHeaderRow >
                <SidebarShareRow style={{ marginBottom: "5px" }} onClick={handleHandRaised}>
                    {checkUserRaiseHand(handRaisedIds, myId, "mobile")}
                    <HeaderColText style={{ margin: "0px" }}>
                        Raise hand
                    </HeaderColText>
                </SidebarShareRow>
            </SidebarHeaderRow>
            {
                (userIds.length > 1 && pinCount) ?
                    (
                        <SidebarHeaderRow >
                            <SidebarShareRow style={{ marginBottom: "5px" }} onClick={() => { openSidebar("sidebarlayout", "SmallDevices"); onClickMobileSidebarClose() }}>
                                <img className="MobileSidebarmenuIcon" src={LayoutSVG} />
                                <HeaderColText style={{ margin: "0px" }}>
                                    Layout
                                </HeaderColText>
                            </SidebarShareRow>
                        </SidebarHeaderRow>
                    ) :
                    <></>
            }
            <SidebarHeaderRow>
                <SidebarShareRow
                    style={{ marginBottom: "5px" }}
                    // onClick={() => { openSidebar("sidebarinfo"); onClickMobileSidebarClose() }}
                    onClick={() => copyURL(roomId, messageApi)}
                >
                    <IoCopyOutline className="MobileSidebarmenuIcon" size={18} color="white" />
                    {/* <img className="MobileSidebarmenuIcon" src={Group2} /> */}
                    <HeaderColText style={{ margin: "0px" }}>
                        Copy URL
                    </HeaderColText>
                </SidebarShareRow>
            </SidebarHeaderRow>
            <SidebarHeaderRow>
                <SidebarShareRow style={{ marginBottom: "5px" }} onClick={() => { openSidebar("sidebarmembers"); onClickMobileSidebarClose() }}>
                    <img className="MobileSidebarmenuIcon" src={Path1} />
                    <HeaderColText style={{ margin: "0px" }}>
                        Participants
                    </HeaderColText>
                </SidebarShareRow>
            </SidebarHeaderRow>
            <SidebarHeaderRow>
                <SidebarShareRow style={{ marginBottom: "5px" }} onClick={() => { openSidebar("sidebarchat"); onClickMobileSidebarClose() }}>
                    <img className="MobileSidebarmenuIcon" src={Path2} />
                    <HeaderColText style={{ margin: "0px" }}>
                        Chat
                    </HeaderColText>
                </SidebarShareRow>
            </SidebarHeaderRow>
            <SidebarHeaderRow>
                <SidebarShareRow style={{ marginBottom: "5px" }} onClick={() => { openSidebar("sidebartranscribe"); onClickMobileSidebarClose() }}>
                    <img className="MobileSidebarmenuIcon" src={Transcibe} />
                    <HeaderColText style={{ margin: "0px" }}>
                        Transcribe
                    </HeaderColText>
                </SidebarShareRow>
            </SidebarHeaderRow>
            {/* <SidebarHeaderRow>
                <SidebarShareRow style={{ marginBottom: "20px" }} onClick={() => { openSidebar("sidebarshare"); onClickMobileSidebarClose() }}>
                    <img className="MobileSidebarmenuIcon" src={Solid} />
                    <HeaderColText style={{ margin: "0px" }}>
                        Screen Share
                    </HeaderColText>
                </SidebarShareRow>
            </SidebarHeaderRow> */}
        </Sidebar>
    );
}


export default MobileSidebar;