import axios from "axios";

// const api = axios.create({ baseURL: "https://localhost:3300/api" });
const api = axios.create({ baseURL: "https://call.workw.com/api" });
// const api = axios.create({ baseURL: "https://192.168.0.101:3300/api" });
// const api = axios.create({ baseURL: "https://58.65.211.234:8091/api" });


let PREFIX = "";
let BASE_URL = ""
let AUTH = ""


// console.log(process.env.REACT_APP_WORKW_ENV)


if (process.env.REACT_APP_WORKW_ENV == "development") {
  console.log("===========DEVELOPMENT ENVIRONMENT============")
  BASE_URL = "58.65.211.234:4436";
  PREFIX = "konnectapi";
  AUTH = "Konnectauth";
}
else {
  console.log("============PRODUCTION ENVIRONMENT============")
  BASE_URL = "www.workw.com";
  PREFIX = "workwapi";
  AUTH = "authapi";
}


export async function deleteRoom(roomId, accessToken) {
  try {
    const result = await api.get(`/deleteRoom/${roomId}`,
      {},
      {
        headers: { authorization: `Bearer ${accessToken}` },
      }
    );

    if (result.status === 200) {
      return true;
    } else {
      throw "deleteRoom api error";
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function updateRoom(roomId, data, accessToken) {
  try {
    const result = await api.put(`/updateRoom/${roomId}`,
      data,
      {
        headers: { authorization: `Bearer ${accessToken}` },
      }
    );

    if (result.status === 200) {
      return result;
    } else {
      throw "updateRoom api error";
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function EndForAll(roomId) {
  try {
    const result = await api.post(`/endcallforAll`, {
      meetingId: roomId,
    });

    if (result.status === 200) {
      return true;
    } else {
      throw " endcallforAll api error";
    }
  } catch (error) {
    console.log("end for all error", error);
    return false;
  }
}
export async function checkRoom(roomId) {
  try {
    const result = await api.get(`/checkroom/${roomId}`);
    if (result.status === 200) {
      return result.data;
    } else {
      throw "checkroom api error";
    }
  } catch (error) {
    console.log("encrpytLink error", error);
    return false;
  }
}
export async function getScheduleByIdExternal(scheduleId) {
  const scheduleDetails = await axios.get(`https://${BASE_URL}/${PREFIX}/api/Schedule/GetScheduleByIdForExternal?id=${scheduleId}`, {
    // headers: {
    //   'Authorization': `Bearer `
    // }
  });
  return ({
    "startDate": scheduleDetails.data.data.startDate,
    "endDate": scheduleDetails.data.data.endDate,
  })
}
export async function updateScheduleMeetingTime(token, scheduleId, isStartTime) {
  return await axios.get(`https://${BASE_URL}/${PREFIX}/api/Schedule/UpdateScheduleMeetingTime?id=${scheduleId}&isStartTime=${isStartTime}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
export async function updateScheduleMemberMeetingTime(token, scheduleId, isStartTime) {
  return await axios.get(`https://${BASE_URL}/${PREFIX}/api/Schedule/updateScheduleMemberMeetingTime?id=${scheduleId}&isStartTime=${isStartTime}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
export async function updateScheduleMeetingTimeByExternal(scheduleId, isStartTime, email) {
  return await axios.get(`https://${BASE_URL}/${PREFIX}/api/Schedule/UpdateScheduleMeetingTimeByExternal?id=${scheduleId}&isStartTime=${isStartTime}&email=${email}`, {});
}
export async function updateScheduleMemberMeetingTimeByExternal(scheduleId, isStartTime, email) {
  return await axios.get(`https://${BASE_URL}/${PREFIX}/api/Schedule/updateScheduleMemberMeetingTimeByExternal?id=${scheduleId}&isStartTime=${isStartTime}&email=${email}`, {});
}
export async function getTurnServer() {
  try {
    const result = await api.get(`/twillio/iceservers`);
    if (result.status === 200) {
      return await result.data.iceServers;
    } else {
      throw "turn error";
    }
  } catch (error) {
    console.log("turn server error", error);
    return false;
  }
}

export async function authenticateUserToken(token) {
  try {
    if (token != undefined) {
      // const userDetailsSplitted = token.split(".");
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      const userId = JSON.parse(jsonPayload).userId;
      const userDetails = await axios.get(`https://${BASE_URL}/${PREFIX}/api/Employee/GetEmployeeById?id=${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("USER", userDetails)
      if (!userDetails?.data) {
        return false;;
      }
      return userDetails.data.data;
      // return jsonPayload;
    }
    else {
      throw ('AuthToken not supplied ')
    }
  } catch (error) {
    console.log("authenticateUserToken Failed ", error);
    return false;
  }
}

export async function guestCallStarted({
  userId,
  accessToken,
  email,
  name,
  roomPassword,
  roomId,
  isRoomPassword,
}) {
  try {
    const result = await api.post(`/guestjoinroom/${roomId}`, {
      userId,
      accessToken,
      email,
      name,
      roomPassword,
      isRoomPassword
    });
    if (result) {
      return result.data;
    } else {
      throw "join call error";
    }
  } catch (error) {
    console.log("ERROR", error.response.data)
    return error;
  }
}

export async function authCallStarted({
  email,
  password,
  roomPassword,
  roomId,
  isRoomPassword,
}) {
  try {
    const result = await api.post(`/authjoinroom/${roomId}`, {
      email,
      password,
      roomPassword,
      isRoomPassword,
    });
    // console.log(result)
    return result;
  } catch (error) {
    return error;
  }
}

export async function getAllUsersRefrenceAPI(accessToken) {
  const data = {
    disableFilter: 0
  }
  const users = await axios.get(`https://${BASE_URL}/${PREFIX}/api/Reference/GetAllUserReference`,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    },
    data
  );
  return users.data.data;
}

export async function AddScheduleExternalMemberAPI(accessToken, externalEmails, scheduleId) {
  const response = await axios.post(`https://${BASE_URL}/${PREFIX}/api/Schedule/AddScheduleExternalMember?id=${scheduleId}`,
    externalEmails,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  // console.log(response.data)
  return !response?.data?.data.length ? response?.data : response?.data?.data;
}
export async function AddScheduleMemberAPI(accessToken, memberObjects, scheduleId) {
  const response = await axios.post(`https://${BASE_URL}/${PREFIX}/api/Schedule/AddScheduleMember?id=${scheduleId}`,
    memberObjects,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  // console.log(response.data)
  return !response?.data?.data.length ? response?.data : response?.data?.data;
}


export async function addParticipantsAPI(data, accessToken) {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://call.workw.com/api/addParticipants',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    data: data
  };
  const response = await axios.request(config);
  return response.data
}

export async function AddCallingTranscribe(data) {
  const response = await axios.post(`https://${BASE_URL}/${PREFIX}/api/Calling/AddCallingTranscribe`,
    data,
    {
      headers: {
        // 'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  console.log("AddCallingTranscribe: ",response.data)
  return 
  // return response?.data
}
