
import React, { useRef, useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BaseContext } from "../../../../app/BaseConext";
import { message } from "antd";

const AudioStreamTagComponent = ({ userId }) => {
  const dispatch = useDispatch();
  const { SocketConnection } = useContext(BaseContext);
  const audioStream = useRef();

  const muted = useSelector(
    ({ generalSlice }) => generalSlice.userId === userId
  );
  const mic = useSelector(
    ({ roomSlice }) => roomSlice.users[userId]?.uiState?.audioOn
  );
  const currentOutputDevice = useSelector(
    ({ roomSlice }) => roomSlice.uiState.currentOutputDevice
  );
  const myId = useSelector(
    ({ generalSlice }) => generalSlice.userId
  );

  useEffect(() => {
    let mount = true;
    if (mount) {
      SocketConnection.Streams.getUserStreams(userId)
        .then(async ({ audio }) => {
          if (mic && currentOutputDevice && userId === myId) {
            audioStream.current.srcObject = audio;
            audioStream.current.setSinkId(currentOutputDevice)
              .then(() => {
                message.info('Audio Device changed');
                // console.log(`Audio output set to ${currentOutputDevice}`);
              })
              .catch(error => {
                message.error('Error setting audio output:' + error);
                // console.error('Error setting audio output:', error);
              });
          }
        })
        .catch((error) => { });
    }
    return () => {
      mount = false;
    };
  }, [currentOutputDevice]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      SocketConnection.Streams.getUserStreams(userId)
        .then(async ({ audio }) => {
          if (mic) {
            audioStream.current.srcObject = audio;
          }
        })
        .catch((error) => console.error(error));
    }
    return () => {
      mount = false;
    };
  }, [audioStream, mic]);

  return (
    <>
      {mic ? (
        <audio ref={audioStream} autoPlay muted={muted}></audio>
      ) : (
        <></>
      )}
    </>
  );
};
export default AudioStreamTagComponent;
