import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import socketSlice from "../domain/SharedSlices/socketSlice/socketSlice";
import roomSlice from "../features/call/store/slice";
import joinCallSlice from "../features/join_call/store/slice";
import generalSlice from "../domain/SharedSlices/generalslice/generalSlice";

const reducers = combineReducers({
  generalSlice,
  socketSlice,
  roomSlice,
  joinCallSlice,
});
const reducer = persistReducer({ key: "root", storage }, reducers);
const middleware = (gDM) =>
  gDM({
    serializableCheck: false,
  }).concat(/*process.env.REACT_APP_IS_ENV_DEVELOPMENT &&  logger*/);
const store = configureStore({ reducer, middleware });
export default store;
