function callStarted(
  state,
  {
    payload: {
      data: {
        roomData: { isPrivate, adminIds },
        userData: { userName, userId, admin, accessToken },
      },
      email,
      scheduleId,
      name,
      roomId,
    },
  }
) {
  return {
    ...state,
    isPrivate,
    userId,
    userName,
    accessToken,
    email,
    scheduleId,
    roomId,
    admin,
    adminIds,
    callstarted: true,
    joinDateTime : new Date().getTime()
  };
}
function callEnded(state, _) {
  return {
    accessToken: "",
    isPublic: "",
    userId: "",
    userName: "",
    email: "",
    scheduleId: "",
    roomId: "",
    admin: false,
    callstarted: false,
    joinDateTime: 0
  };
}



function updateTokenQueryOrCookie(state, { payload }) {
  return {
    ...state,
    accessToken: payload
  };
}
function updateHandRaisedIds(state, { payload }) {
  return {
    ...state,
    handRaisedIds: payload
  }
}

const generalSliceActions = {
  updateHandRaisedIds,
  updateTokenQueryOrCookie,
  callStarted,
  callEnded,
};
export default generalSliceActions;
