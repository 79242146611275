import { createSlice } from "@reduxjs/toolkit";
import socketSliceAction from "./socketSliceActions";
export const socketSlice = createSlice({
  name: "socketSlice",
  initialState: {
    socketConnectionState: {
      isLoading: true,
      connectionState: null,
      error: "",
    },
  },
  reducers: socketSliceAction,
});

export default socketSlice.reducer;
