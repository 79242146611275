import { createSlice } from "@reduxjs/toolkit";
import generalSliceActions from "./generalSliceActions";
const initialState = {
  accessToken: "",
  isPrivate: "",
  userId: "",
  userName: "",
  email: "",
  roomId: "",
  scheduleId: "",
  handRaisedIds: [],
  adminIds: [],
  admin: false,
  callstarted: false,
  joinDateTime: 0
};

export const generalSlice = createSlice({
  name: "generalSlice",
  initialState: initialState,
  reducers: generalSliceActions,
});

export default generalSlice.reducer;
