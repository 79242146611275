export async function updateAudioTrack(state, { MyStreams, SocketConnection }) {
  return await MyStreams.getInstance().then(({ audioStream }) => {
    // console.log(state)
    if (!audioStream) return { audioOn: false };
    const audioTracks = audioStream
      .getTracks()
      .find((track) => track.kind === "audio");

    if (audioTracks) {
      if (state.uiState.audioOn) {
        SocketConnection.UserAudioOff();
      } else {
        SocketConnection.UserAudioOn();
      }
      audioTracks.enabled = !state.uiState.audioOn;
      return { audioOn: !state.uiState.audioOn };
    }
  });
}
